angular.module('main')
    .directive('calculatorInventoryEmissions', function() {
        return {
            scope: {
                indicator: '=',
                container: '=',
                type: '=',
                id: '='
            },
            restrict: 'E',
            replace: true,
            templateUrl: 'calculator-inventory-emissions.tpl.html',
            controller: controller,
            controllerAs: 'vm',
            bindToController: true
        };

        function controller($uibModal,$scope, companyCertificationService, countryService) {
            var vm = this;
            vm.addEquipment = addEquipment;
            vm.save = save;
            vm.saveMeasurer = saveMeasurer;
            vm.answerFilter = answerFilter;
            vm.showConfirmation = showConfirmation;
            vm.labelType = 'significativa';
            vm.emissionType = [
                { id: '1', name: 'Viajes aéreos', type: 'Passenger Transport'},
                { id: '2', name: 'Transporte tercerizado', type: 'Mobile Combustion',dynamic_name: 'Tipo de combustible' },
                { id: '3', name: 'Residuos', type: 'Waste',dynamic_name: 'Tipo de residuos'   }
            ];

            if (vm.type == 'indirect') {
                vm.labelType = 'indirecta';
                loadCountries();
                vm.emissionType = [
                    { id: '1', name: 'Electricidad', type: 'Electrical' },
                ];
            }
            if (vm.type == 'direct') {
                vm.labelType = 'directa';
                vm.emissionType = [
                    { id: '1', name: 'Combustión estacionaria', type: 'Stationary Combustion',dynamic_name: 'Tipo de combustible' },
                    { id: '2', name: 'Combustión movil', type: 'Mobile Combustion',dynamic_name: 'Tipo de combustible'  },
                    { id: '3', name: 'Emisiones fugitivas', type: 'Fugitive Emissions',dynamic_name: 'Tipo de gas'  },
                    { id: '4', name: 'Industria', type: 'Industries',dynamic_name: 'Tipo de producción'  },
                    { id: '5', name: 'Agropecuaria', type: 'Crops', dynamic_name: 'Tipo de actividad'  },
                    { id: '6', name: 'Residuos', type: 'Waste',dynamic_name: 'Tipo de residuos'  },
                ];

            }

            vm.modelSelectOption = vm.emissionType[0];
           
            loadEmissionActivities();
            loadUnitOfMeasure();

            if (typeof vm.indicator.answers === 'undefined') {
                loadAnswers();
                if (vm.indicator.name == 'Emisiones significativas') {
                    loadAirports(); 
                }
            }else{
                answerFilter();
            }

           
            function loadAnswers() {
                $type = 'equipment';
                if (vm.type == 'indirect') {
                    $type = 'branch-office';
                }
                companyCertificationService.loadIndicatorAnswersCalculator(vm.id, vm.indicator.id, $type)
                    .then(success)
                    .catch(error);

                function success(response) {
                    vm.indicator.answers = response.data;
                    answerFilter();
                }

                function error(error) {
                    console.log(error);
                }
            }

            function loadEmissionActivities() {
                companyCertificationService.loadEmissionActivities()
                    .then(success)
                    .catch(error);

                function success(response) {
                    vm.emissionActivities = response.data;
                }

                function error(error) {
                    console.log(error);
                }
            }

            function loadUnitOfMeasure() {
                companyCertificationService.loadUnitOfMeasure()
                    .then(success)
                    .catch(error);

                function success(response) {
                    vm.loadUnitOfMeasure = response.data;
                }

                function error(error) {
                    console.log(error);
                }
            }

            function loadAirports() {
                companyCertificationService.loadAirports()
                .then(success)
                .catch(error);
                function success(response) {
                  vm.indicator.loadAirports = response.data;
                }
                function error(error) {
                    console.log(error);
                }  
            }

            
            function addEquipment() {

                    data = { 
                        "indicator_id": vm.indicator.id ,
                        "emission" : vm.type,
                       "company_certification_id": vm.id};

                    $type = 'equipment';
                    if (vm.type == 'indirect') {
                        $type = 'branch-office';
                        data = { 
                            "indicator_id": vm.indicator.id ,
                           "company_certification_id": vm.id,
                           "emission" : vm.type,
                           "sucursal_id": vm.indicator.answersfilter[0].id};
                    }
                    companyCertificationService.addResource( data,$type )
                    .then(success)
                    .catch(error);

                function success(response) {
                    loadAnswers();
                    vm.loadAirports = response.data;
                }

                function error(error) {
                    console.log(error);
                }
            }

            function save() {
                data = {
                    "indicator_id": vm.indicator.id,
                    "company_certification_id": vm.id,
                    "answers": vm.indicator.answersfilter,
                    "emission": vm.type,
                    "type": vm.modelSelectOption.id
                };
                companyCertificationService.saveIndicatorAnswersCalculator(data, 'equipment')
                    .then(success)
                    .catch(error);

                function success(response) {
                    loadAnswers();
                }

                function error(error) {
                    console.log(error);
                }
            }

            function saveMeasurer() {
                vm.indicator.answersfilter[0].country_id = vm.indicator.answersfilter[0].country_id.id;
                data = {
                    "indicator_id": vm.indicator.id,
                    "company_certification_id": vm.id,
                    "answers": vm.indicator.answersfilter,
                    "emission": vm.type,
                    "type": vm.modelSelectOption.id
                };
                companyCertificationService.saveIndicatorAnswersCalculator(data, 'branch-office')
                    .then(success)
                    .catch(error);

                function success(response) {
                    loadAnswers();
                }

                function error(error) {
                    console.log(error);
                }
            }

            function answerFilter() {

                vm.indicator.answersfilter = [];
                option = vm.modelSelectOption != null ? vm.modelSelectOption.id : null;
                if (vm.type == 'indirect') {
                    vm.indicator.answersfilter = vm.indicator.answers;
                    vm.indicator.answersfilter[0].country_id = { 'id': vm.indicator.answersfilter[0].country_id };
                } else {
                    const filter = [];
                    for (var index = 0; index < vm.indicator.answers.length; index++) {

                        if (option == null) {
                            filter.push(vm.indicator.answers[index]);
                        } else {
                            if (vm.indicator.answers[index].emission_type == option || vm.indicator.answers[index].emission_type == null) {
                                filter.push(vm.indicator.answers[index]);
                            }
                        }
                    }
                    vm.indicator.answersfilter = filter;
                }

            }

            function loadCountries() {
                countryService.findAll()
                    .then(success)
                    .catch(error);

                function success(response) {
                    vm.countries = response;
                }

                function error(error) {
                    console.log(error);
                }
            }
            function showConfirmation(answer, key) {
                $uibModal.open({
                    animation: true,
                    component: 'deleteEquipment',
                    resolve: {
                        emissionAnswer: function () {
                            return answer;
                        },
                        key: function () {
                            return key;
                        },
                        loadAnswers: function () {
                            return loadAnswers;
                        }
                    }
                });
              }

          

        }
    });