angular.module('main')
    .directive('calculatorTotalMonths', function() {
        return {
            scope: {
                indicator: '=',
                id: '=',
                parentContainer: '=',
                option: '=',
            },
            restrict: 'E',
            templateUrl: 'calculator-total-months.tpl.html',
            controller: controller,
            controllerAs: 'vm',
            bindToController: true
        };

        function controller(companyCertificationService,companyCertification) {
            var vm = this;
          
            vm.grafic = grafic;

         
            
            
           
            vm.month = [
                    {id: 'january', name: 'Enero'},
                    {id: 'february', name: 'Febrero'},
                    {id: 'march', name: 'Marzo'},
                    {id: 'april', name: 'Abril'},
                    {id: 'may', name: 'Mayo'},
                    {id: 'june', name: 'Junio'},
                    {id: 'july', name: 'Julio'},
                    {id: 'august', name: 'Agosto'},
                    {id: 'september', name: 'Septiembre'},
                    {id: 'october', name: 'Octubre'},
                    {id: 'november', name: 'Noviembre'},
                    {id: 'december', name: 'Diciembre'}
                  ]; 
            if (vm.option ==0) {          
                loadTotalesAnuales();
            }else{
                filterContainer();
                loadTotalesMensuales(); 
            }
         

            
         function loadTotalesMensuales() {
            companyCertificationService.loadEmissionTotals(companyCertification.id)
            .then(success)
            .catch(error);
            function success(response) {
                vm.emissionTotals =  [];
                vm.totalMount =  response.data;
                grafic( 'january');
            }

            function error(error) {
                console.log(error);
            }               
         }

         function loadTotalesAnuales() {

            companyCertificationService.loadTotalsYears(companyCertification.certification_id,companyCertification.officeId)
            .then(success)
            .catch(error);
            function success(response) {
                vm.emissionTotals =  [];
                vm.month = response.select;
                vm.totalMount =  response.data;
                vm.totalYearMount =  response.dataMonths;
                vm.optionSelect = vm.month[0];
                grafic( companyCertification.year);
             
            }

            function error(error) {
                console.log(error);
            }               
         }


         vm.optionSelect = vm.month[0];
         function filterContainer() {
            const element = vm.parentContainer.subContainers;

           
            vm.containerFilter = [];
            const names = ["Emisiones significativas","Emisiones indirectas","Emisiones directas"];
            for (var index = 0; index < element.length; index++) {
                if ( names.includes(element[index].name)) {
                    for (var key = 0; key < element[index].yearlyIndicators.length; key++) {
                        if (typeof element[index].yearlyIndicators[key].answers !== 'undefined') {
                            element[index].yearlyIndicators[key].answers = []; 
                        }  
                    }
                    vm.containerFilter.push( element[index]);
                }
            }
            
         }
         
         function grafic(key) {
          var dom = document.getElementById('echart');
          var existingInstance = echarts.getInstanceByDom(dom);
          if (existingInstance) {
            existingInstance.dispose();
           }
          var myChart = echarts.init(dom, null, {
            renderer: 'canvas',
            useDirtyRect: false
          });
            if (vm.option) {
              
               var valueMount = vm.totalMount[key];
         
            setTimeout(function () {
                option = {
                  legend: {
                    orient: 'vertical',
                    left: 'left'
                  },
                  tooltip: {
                    showContent: false
                  },
                  dataset: {
                    source: [
                      ['product', 'Emiciones en Toneladas',],
                      ['Emisiones Directas', valueMount.direct],
                      ['Emisiones Indirectas', valueMount.indirect],
                      ['Emisiones Significativas', valueMount.significant]
                    ]
                  },
                  xAxis: { type: 'category' },
                  yAxis: { gridIndex: 0 },
                  grid: { top: '35%' },
                  series: [
                    {
                      type: 'bar',
                      smooth: true,
                      barMaxWidth: '80',
                      seriesLayoutBy: 'row',
                      emphasis: { focus: 'series' }
                    },
                    {
                      type: 'bar',
                      barMaxWidth: '80',
                      smooth: true,
                      seriesLayoutBy: 'row',
                      emphasis: { focus: 'series' }
                    },
                    {
                      type: 'bar',
                      smooth: true,
                      barMaxWidth: '80',
                      seriesLayoutBy: 'row',
                      emphasis: { focus: 'series' }
                    },
                   
                    {
                      type: 'pie',
                      id: 'pie',
                      radius: '18%',
                      center: ['50%', '15%'],
                      emphasis: {
                        focus: 'self'
                      },
                      label: {
                        formatter: '{b}: {@Emiciones en Toneladas} ({d}%)'
                      },
                      encode: {
                        itemName: 'product',
                        value: 'Emiciones en Toneladas',
                        tooltip: 'Emiciones en Toneladas'
                      }
                    }
                  ]
                };
                myChart.on('updateAxisPointer', function (event) {
                  const xAxisInfo = event.axesInfo[0];
                  if (xAxisInfo) {
                    const dimension = xAxisInfo.value + 1;
                    myChart.setOption({
                      series: {
                        id: 'pie',
                        label: {
                          formatter: '{b}: {@[' + dimension + ']} ({d}%)'
                        },
                        encode: {
                          value: dimension,
                          tooltip: dimension
                        }
                      }
                    });
                  }
                });
                myChart.setOption(option);
              });
            
        

            }else{
                
                valueMount = vm.totalYearMount[key];

                setTimeout(function () {
                    option = {
                      legend: {
                        orient: 'vertical',
                        left: 'left'
                      },
                      tooltip: {
                        trigger: 'axis',
                        showContent: false
                      },
                      dataset: {
                        source: [
                          ['month', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                          ['Emisiones Directas', valueMount.direct.january, valueMount.direct.february, valueMount.direct.march, valueMount.direct.april, valueMount.direct.may, valueMount.direct.june, valueMount.direct.july, valueMount.direct.august, valueMount.direct.september, valueMount.direct.october, valueMount.direct.november, valueMount.direct.december],
                          ['Emisiones Indirectas', valueMount.indirect.january, valueMount.indirect.february, valueMount.indirect.march, valueMount.indirect.april, valueMount.indirect.may, valueMount.indirect.june, valueMount.indirect.july, valueMount.indirect.august, valueMount.indirect.september, valueMount.indirect.october, valueMount.indirect.november, valueMount.indirect.december],
                          ['Emisiones Significativas', valueMount.significant.january, valueMount.significant.february, valueMount.significant.march, valueMount.significant.april, valueMount.significant.may, valueMount.significant.june, valueMount.significant.july, valueMount.significant.august, valueMount.significant.september, valueMount.significant.october, valueMount.significant.november, valueMount.significant.december]

                        ]
                      },
                      xAxis: { type: 'category' },
                      yAxis: { gridIndex: 0 },
                      grid: { top: '35%' },
                      series: [
                        {
                          type: 'line',
                          smooth: true,
                          barMaxWidth: '80',
                          seriesLayoutBy: 'row',
                          emphasis: { focus: 'series' }
                        },
                        {
                          type: 'line',
                          barMaxWidth: '80',
                          smooth: true,
                          seriesLayoutBy: 'row',
                          emphasis: { focus: 'series' }
                        },
                        {
                          type: 'line',
                          smooth: true,
                          barMaxWidth: '80',
                          seriesLayoutBy: 'row',
                          emphasis: { focus: 'series' }
                        },
                       
                        {
                          type: 'pie',
                          id: 'pie',
                          radius: '18%',
                          center: ['50%', '15%'],
                          emphasis: {
                            focus: 'self'
                          },
                          label: {
                            formatter: '{b}: {@Jan} ({d}%)'
                          },
                          encode: {
                            itemName: 'month',
                            value: 'Jan',
                            tooltip: 'Jan'
                          }
                        }
                      ]
                    };
                    myChart.on('updateAxisPointer', function (event) {
                      const xAxisInfo = event.axesInfo[0];
                      if (xAxisInfo) {
                        const dimension = xAxisInfo.value + 1;
                        myChart.setOption({
                          series: {
                            id: 'pie',
                            label: {
                              formatter: '{b}: {@[' + dimension + ']} ({d}%)'
                            },
                            encode: {
                              value: dimension,
                              tooltip: dimension
                            }
                          }
                        });
                      }
                    });
                    myChart.setOption(option);
                  });
                
            }


           /*  if (option && typeof option === 'object') {
                myChart.setOption(option);
              } */
              
              window.addEventListener('resize', myChart.resize);


        
         }
          


        }
    });