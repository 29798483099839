angular.module('main')
        .directive('formIndicatorRecord', function () {
            return {
                scope: {
                    resolve: '<',
                    close: '&',
                    dismiss: '&'
                },
                restrict: 'E',
                templateUrl: 'form-indicator-record.tpl.html',
                controller: controller
            };

            function controller($scope, $uibModal) {
                if(typeof $scope.resolve.parent !== 'undefined') {
                    $scope.resolve.indicators.map(mapIndicator);
                }
                
                function mapIndicator(indicator) {
                    indicator.answer = $scope.resolve.parent.subAnswers.filter(filterAnswer)[0];
                    
                    function filterAnswer (answer) {
                        if(answer.indicator.id === indicator.id) {
                            return answer;
                        }
                    }
                }
                
                $scope.save = save;
                $scope.openModalHelper = openModalHelper;
                
                function save() {
                    $scope.close({$value: $scope.resolve.indicators});
                }
                function openModalHelper(indicator) {
                    var modal =  $uibModal.open({
                          animation: true,
                          component: 'helper',
                          windowClass: 'helper-modal',
                          size: 'lg',
                          resolve: {
                           indicator : indicator
                          }
                      });
                }
            }
        });