angular.module('main')
    .directive('companyCertifications', function() {
        return {
            restrict: 'E',
            transclude: true,
            replace: true,
            templateUrl: 'company-certifications.tpl.html',
            controller: controller,
            controllerAs: 'vm'
        };

        function controller($uibModal, companyService, company, user, userService) {
            var vm = this;
            vm.startCertification = startCertification;
            vm.company = company;
            vm.showConfirmation = showConfirmation;
            vm.hasPermissions = hasPermissions;
            vm.yearChange = yearChange;
            vm.officeChange = officeChange;
            vm.percentageChange = percentageChange;
            vm.ifReport = ifReport;
            vm.changeModule = changeModule;
         
            vm.years = [];
            vm.offices = [];
            vm.percentages = [{name: 'Todos',first: 0,last: 130,},{name: '0-20%',first: 0,last: 20,},{name: '20%-40%',first: 20,last: 40,},{name: '40%-60%',first: 40,last: 60,},{name: '60%-80%',first: 60,last: 80,},{name: '80%-100%',first: 80,last: 130,}];
            vm.year = "";
            vm.filteredCertifications = [];
            vm.groupName = {};
            vm.consolidated = "NA";
            vm.user = user;
    

           

            function hasPermissions(roles) {
                return userService.hasPermissions(user, roles);
            }

            function showConfirmation(certification, key) {
                $uibModal.open({
                    animation: true,
                    component: 'deleteCertificationSucursal',
                    resolve: {
                        certification: function() {
                            return certification;
                        },
                        key: function() {
                            return key;
                        },
                        loadCertifications: function() {
                            return loadStartedCertifications;
                        }
                    }
                });
            }

            function startCertification(certifications, key) {
                $uibModal.open({
                    animation: true,
                    component: 'startCertification',
                    resolve: {
                        certifications: function() {
                            return certifications;
                        },
                        key: function() {
                            return key;
                        }
                    }
                });
            }

            loadStartedCertifications();

            function loadStartedCertifications(date) {
                if (!date) {
                     date = new Date().getFullYear(); 
                }
               
                companyService.findCompanyCertifications(date)
                    .then(success)
                    .catch(error);

                function success(response) {
                    
                    var availableCertifications = vm.company.availableCertifications;
                    var groupedCertifications = {};
                    for (var i = 0; i < availableCertifications.length; i++) {
                      var certification = availableCertifications[i];
                      if (!groupedCertifications[certification.groupName]) {
                        groupedCertifications[certification.groupName] = [];
                      }
                      groupedCertifications[certification.groupName].push(certification);
                    
                    }
                    for (var groupName in groupedCertifications) {
                      if (groupedCertifications.hasOwnProperty(groupName)) {
                        var certifications = groupedCertifications[groupName];
                        changeModule(groupName,certifications)
                      break;
                      }
                    }

                    vm.startedCertifications = response;

                   
                    vm.consolidated = JSON.stringify(response);
                    getYears(response,date);
                  
                }


                function error(error) {
                    console.log(error);
                }
            }

            function getYears(certifications,yearNow) {

                console.log(certifications,yearNow);
                var years = [];
                var offices = [];
                vm.groupName = {};
                certifications.forEach(function(cert) {
                  
                        if (!years.includes(cert.year)) {
                            years.push(cert.year);
                        }
                        if (!offices.includes(cert.office)) {
                            offices.push(cert.office);
                        }
                    
                   
                });

                vm.years = years.sort(function(a, b) { return b - a }); // Sorts in descending order.
                vm.years.unshift("Todos"); // Adds this option to the first position.
                vm.year = vm.years[0];

                vm.offices = offices.sort(function(a, b) { return b - a }); // Sorts in descending order.
                vm.offices.unshift("Todos"); // Adds this option to the first position.


                certifications.forEach(function(key) {
                    if (key.year == yearNow || yearNow === 'Todos') {
                        if (!vm.groupName[key.groupName]) {
                            vm.groupName[key.groupName] = {
                                year: yearNow,
                                certification: vm.years[0],
                                office: vm.years[0],
                                percentage: vm.percentages[0],
                                filteredCertications: [],
                            };
                        }
                        vm.filteredCertifications.push(key);
                        vm.groupName[key.groupName].filteredCertications.push(key);
                    }
                  

                });


            }

            function yearChange(key) {

                loadStartedCertifications(key);
               /*  vm.filteredCertifications = [];
                vm.groupName[key].filteredCertications = [];

                vm.startedCertifications.forEach(function(cert) {
                    if (vm.groupName[key].year !== "Todos") {
                        if (cert.year === vm.groupName[key].year && cert.groupName === key) {
                            vm.filteredCertifications.push(cert);
                            vm.groupName[key].filteredCertications.push(cert);
                        }
                    } else if (cert.groupName === key) {
                        vm.filteredCertifications.push(cert);
                        vm.groupName[key].filteredCertications.push(cert);
                    }
                });

                if (vm.groupName[key].office !== "Todos") {
                    var volatil = vm.groupName[key].filteredCertications;
                    vm.groupName[key].filteredCertications = [];
                    volatil.forEach(function(cert) {
                        if (cert.office === vm.groupName[key].office && cert.groupName === key) {
                            vm.groupName[key].filteredCertications.push(cert);
                        }
                    });
                }
                if (vm.groupName[key].percentage !== "Todos") {
                    var volatil = vm.groupName[key].filteredCertications;
                    vm.groupName[key].filteredCertications = [];
                    volatil.forEach(function(cert) {
                        if ( cert.percentage >= vm.groupName[key].percentage.first && cert.percentage <= vm.groupName[key].percentage.last && cert.groupName === key) {
                            vm.filteredCertifications.push(cert);
                            vm.groupName[key].filteredCertications.push(cert);
                        }
                    });
                } */
            }

            function officeChange(key) {
                vm.filteredCertifications = [];
                vm.groupName[key].filteredCertications = [];

                vm.startedCertifications.forEach(function(cert) {
                    if (vm.groupName[key].office !== "Todos") {
                        if (cert.office === vm.groupName[key].office && cert.groupName === key) {
                            vm.filteredCertifications.push(cert);
                            vm.groupName[key].filteredCertications.push(cert);
                        }
                    } else if (cert.groupName === key) {
                        vm.filteredCertifications.push(cert);
                        vm.groupName[key].filteredCertications.push(cert);
                    }
                });

                if (vm.groupName[key].year !== "Todos") {
                    var volatil = vm.groupName[key].filteredCertications;
                    vm.groupName[key].filteredCertications = [];
                    volatil.forEach(function(cert) {
                        if (cert.year === vm.groupName[key].year && cert.groupName === key) {
                            vm.groupName[key].filteredCertications.push(cert);
                        }
                    });
                }

                if (vm.groupName[key].percentage !== "Todos") {
                    var volatil = vm.groupName[key].filteredCertications;
                    vm.groupName[key].filteredCertications = [];
                    volatil.forEach(function(cert) {
                        if ( cert.percentage >= vm.groupName[key].percentage.first && cert.percentage <= vm.groupName[key].percentage.last && cert.groupName === key) {
                            vm.filteredCertifications.push(cert);
                            vm.groupName[key].filteredCertications.push(cert);
                        }
                    });
                }
            }

            function percentageChange(key) {
                vm.filteredCertifications = [];
                vm.groupName[key].filteredCertications = [];

                vm.startedCertifications.forEach(function(cert) {
                    if (vm.groupName[key].percentage.name !== "Todos") {
                        if ( cert.percentage >= vm.groupName[key].percentage.first && cert.percentage <= vm.groupName[key].percentage.last && cert.groupName === key) {
                            vm.filteredCertifications.push(cert);
                            vm.groupName[key].filteredCertications.push(cert);
                        }
                    } else if (cert.groupName === key) {
                        vm.filteredCertifications.push(cert);
                        vm.groupName[key].filteredCertications.push(cert);
                    }
                });
                if (vm.groupName[key].year !== "Todos") {
                    var volatil = vm.groupName[key].filteredCertications;
                    vm.groupName[key].filteredCertications = [];
                    volatil.forEach(function(cert) {
                        if (cert.year === vm.groupName[key].year && cert.groupName === key) {
                            vm.groupName[key].filteredCertications.push(cert);
                        }
                    });
                }

                if (vm.groupName[key].office !== "Todos") {
                    var volatil = vm.groupName[key].filteredCertications;
                    vm.groupName[key].filteredCertications = [];
                    volatil.forEach(function(cert) {
                        if (cert.office === vm.groupName[key].office && cert.groupName === key) {
                            vm.groupName[key].filteredCertications.push(cert);
                        }
                    });
                }

            }

            function ifReport(key) {

              
                $valor = false;
                if (key === 'Módulos Susty' && vm.company.id === 54) {
                    $valor = true;
                }
                return $valor;

            }

            function changeModule(key,certifications) {
                vm.load = false;
                vm.key = key;
                vm.certifications = certifications;
                vm.load =true;
            }
        }
    });